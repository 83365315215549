import React from "react";
import { Layout } from "antd";
import { Route, Switch } from "react-router-dom";
import { authenticationRoutes } from "../../../navigation/routes";
import "../../../assets/theme/theme.less";
import "../../../assets/theme/global.css";

const { Content } = Layout;

const PublicLayout = () => {
  console.log({ authenticationRoutes })
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content
        style={{
          padding: "20px 50px",
          backgroundColor: "#F9FBFD",
        }}
      >
        <Switch>
          {authenticationRoutes &&
            authenticationRoutes.map((item, index) => (
              <Route
                key={index}
                exact
                path={item.path}
                component={item.component}
              />
            ))}
        </Switch>
      </Content>
    </Layout>
  );
};

export default PublicLayout;
