
import { call, put, takeLatest } from 'redux-saga/effects';
import {
    GET_PAY_PERIODS,
    GET_PAY_PERIODS_LOADING,
    GET_PAY_PERIODS_SUCCESS,
    GET_PAY_PERIODS_ERROR,

    GET_DEDUCTION_SUMMARY,
    GET_DEDUCTION_SUMMARY_LOADING,
    GET_DEDUCTION_SUMMARY_SUCCESS,
    GET_DEDUCTION_SUMMARY_ERROR,

    GET_DEDUCTION_SUMMARY_FILE,
    GET_DEDUCTION_SUMMARY_FILE_LOADING,
    GET_DEDUCTION_SUMMARY_FILE_SUCCESS,
    GET_DEDUCTION_SUMMARY_FILE_ERROR,

    GET_FILE_BASED_DEDUCTION_REPORT,
    GET_FILE_BASED_DEDUCTION_REPORT_LOADING,
    GET_FILE_BASED_DEDUCTION_REPORT_SUCCESS,
    GET_FILE_BASED_DEDUCTION_REPORT_ERROR,
} from './actions';
import { api } from '../../utils/api';
import { errorhandling } from '../../utils/helper';
import { apiConfig } from '../../utils/apiConfig';


function* getPayPeriods({ body }) {
    yield put({ type: GET_PAY_PERIODS_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'GET',
            url: apiConfig.payPeriod.get_payperiods({ employer_id: body.employerId }),
        });
        yield put({ type: GET_PAY_PERIODS_SUCCESS, data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_PAY_PERIODS_ERROR, errorMessage: '' });
    }
}

function* getDeductionSummary({ body }) {
    yield put({ type: GET_DEDUCTION_SUMMARY_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'GET',
            url: apiConfig.payroll.get_deducations({
                employer_id: body.employerId,
                pay_period_id: body.payPeriod,
                employee_id: body.employeeId,
                item_per_page: body.itemPerPage,
                page_number: body.pageNumber
            }),
        });
        yield put({ type: GET_DEDUCTION_SUMMARY_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_DEDUCTION_SUMMARY_ERROR, errorMessage: '' });
    }
}

function* getFileBasedDeductionReport({ body }) {
    yield put({ type: GET_FILE_BASED_DEDUCTION_REPORT_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'GET',
            url: 'salaryadvance/salary-manager/employer/fileBasedDeductionReport',
            body
        });
        fetch(data.fileUrl, {
            method: 'GET',
            cache: 'no-cache',
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", data.fileUrl.split("/").pop());
                a.click();
                a.remove()
            });
        });
        data.deductions.push({ deductionType: "Total Deductions", amount: data.deductions.reduce((total, item) => total + (item.amount || 0), 0) })
        yield put({ type: GET_FILE_BASED_DEDUCTION_REPORT_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_FILE_BASED_DEDUCTION_REPORT_ERROR, errorMessage: '' });
    }
}

function* getDeductionSummaryFile({ body }) {
    yield put({ type: GET_DEDUCTION_SUMMARY_FILE_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'GET',
            url: 'salaryadvance/salary-manager/employer/deductionReport',
            body
        });

        fetch(data.fileUrl, {
            method: 'GET',
            cache: 'no-cache',
        }).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", data.fileUrl.split("/").pop());
                a.click();
                a.remove()
            });
        });
        yield put({ type: GET_DEDUCTION_SUMMARY_FILE_SUCCESS, data: {} });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_DEDUCTION_SUMMARY_FILE_ERROR, errorMessage: '' });
    }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchDeductionSummarySaga() {
    yield takeLatest(GET_PAY_PERIODS, getPayPeriods)
    yield takeLatest(GET_DEDUCTION_SUMMARY, getDeductionSummary)
    yield takeLatest(GET_DEDUCTION_SUMMARY_FILE, getDeductionSummaryFile)
    yield takeLatest(GET_FILE_BASED_DEDUCTION_REPORT, getFileBasedDeductionReport)
}