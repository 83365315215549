
import { call, put, takeLatest } from 'redux-saga/effects';
import {
    GET_EMPLOYEES,
    GET_EMPLOYEES_LOADING,
    GET_EMPLOYEES_SUCCESS,
    GET_EMPLOYEES_ERROR,


    UPDATE_EMPLOYEE,
    // UPDATE_EMPLOYEE_LOADING,
    UPDATE_EMPLOYEE_SUCCESS,
    UPDATE_EMPLOYEE_ERROR,

    GET_EMPLOYEE,
    GET_EMPLOYEE_LOADING,
    GET_EMPLOYEE_SUCCESS,
    GET_EMPLOYEE_ERROR,


    CREATE_EMPLOYEE,
    // CREATE_EMPLOYEE_LOADING,
    CREATE_EMPLOYEE_SUCCESS,
    CREATE_EMPLOYEE_ERROR

} from './actions';
import { api } from '../../utils/api';
import { errorhandling } from '../../utils/helper';
import { apiConfig } from '../../utils/apiConfig';
import { message } from 'antd'



function* getEmployees({ body }) {
    yield put({ type: GET_EMPLOYEES_LOADING });
    try {
        if (!body.employerId) {
            message.error('Please choose an employer!');
            throw new Error("Please choose an employer!")
        }
        const query = {
            number_page: body.page || 1,
            search: body.search || '',
            employer_id: body.employerId,
            item_per_page: 10
        }
        const { data } = yield call(api, {
            method: 'GET',
            url: apiConfig.employees.get_employees_by_employer({ query }),
        });
        yield put({
            type: GET_EMPLOYEES_SUCCESS, data: {
                list: data,
                count: data.length
            }
        });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_EMPLOYEES_ERROR, errorMessage: '' });
    }
}


function* getEmployeeById({ body }) {
    yield put({ type: GET_EMPLOYEE_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'GET',
            url: apiConfig.employees.get_employee({ employeeId: body.id }),
        });
        yield put({
            type: GET_EMPLOYEE_SUCCESS, data
        });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_EMPLOYEE_ERROR, errorMessage: '' });
    }
}



function* updayeEmployeeById({ body }) {
    body.handleLoading(true)
    try {
        const employee = body.employee;
        const endpoint = apiConfig.employees.update_employee({ employeeId: employee.id })
        const payload = {
            nationalInsuranceNumber: employee.nationalInsuranceNumber,
            firstName: employee.firstName,
            lastName: employee.lastName,
            email: employee.email,
            phoneNumber: employee.phoneNumber,
            ukAccountNumber: employee.ukAccountNumber,
            ukSortCode: employee.ukSortCode,
            position: employee.position,
            dateOfBirth: employee.dateOfBirth,
            workNumber: employee.workNumber
        }
        const { data } = yield call(api, {
            method: 'PUT',
            url: endpoint,
            body: payload
        });
        yield put({
            type: UPDATE_EMPLOYEE_SUCCESS, data
        });
        yield put({ type: GET_EMPLOYEES, body: { employerId: employee.employer.id } })
        body.handleLoading(false, "success")
    } catch (error) {
        errorhandling(error)
        body.handleLoading(false, "failed")
        yield put({ type: UPDATE_EMPLOYEE_ERROR, errorMessage: '' });
    }

}




function* createEmployee({ body }) {
    body.handleLoading(true)
    try {
        const endpoint = apiConfig.employees.create_employee({ employerId: body.employerId })
        const payload = {
            nationalInsuranceNumber: body.nationalInsuranceNumber,
            firstName: body.firstName,
            lastName: body.lastName,
            email: body.email,
            phoneNumber: body.phoneNumber,
            workNumber: body.workNumber,
            accountNumber: body.ukAccountNumber,
            sortCode: body.ukSortCode,
            position: body.position,
            dateOfBirth: body.dateOfBirth,

        }

        const { data } = yield call(api, {
            method: 'POST',
            url: endpoint,
            body: payload
        });
        yield put({
            type: CREATE_EMPLOYEE_SUCCESS, data
        });
        body.closeModal && body.closeModal()
        yield put({ type: GET_EMPLOYEES, body: { employerId: body.employerId } })
        body.handleLoading(false, "success")

    } catch (error) {
        errorhandling(error)
        yield put({ type: CREATE_EMPLOYEE_ERROR, errorMessage: '' });
        body.handleLoading(false, "failed")
    }

}
/**
 * Root saga manages watcher lifecycle
 */
export default function* watchEmployeesSaga() {
    yield takeLatest(GET_EMPLOYEES, getEmployees)
    yield takeLatest(UPDATE_EMPLOYEE, updayeEmployeeById)
    yield takeLatest(CREATE_EMPLOYEE, createEmployee)
    yield takeLatest(GET_EMPLOYEE, getEmployeeById)
}