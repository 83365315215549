export const GET_EMPLOYEES = "GET_EMPLOYEES"
export const GET_EMPLOYEES_LOADING = "GET_EMPLOYEES_LOADING"
export const GET_EMPLOYEES_SUCCESS = "GET_EMPLOYEES_SUCCESS"
export const GET_EMPLOYEES_ERROR = "GET_EMPLOYEES_ERROR"
export const RESET_EMPLOYEES = "RESET_EMPLOYEES"


export const GET_EMPLOYEE = "GET_EMPLOYEE"
export const GET_EMPLOYEE_LOADING = "GET_EMPLOYEE_LOADING"
export const GET_EMPLOYEE_SUCCESS = "GET_EMPLOYEE_SUCCESS"
export const GET_EMPLOYEE_ERROR = "GET_EMPLOYEE_ERROR"
export const RESET_EMPLOYEE = "RESET_EMPLOYEE"

export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE"
export const UPDATE_EMPLOYEE_LOADING = "UPDATE_EMPLOYEE_LOADING"
export const UPDATE_EMPLOYEE_SUCCESS = "UPDATE_EMPLOYEE_SUCCESS"
export const UPDATE_EMPLOYEE_ERROR = "UPDATE_EMPLOYEE_ERROR"



export const CREATE_EMPLOYEE = "CREATE_EMPLOYEE"
export const CREATE_EMPLOYEE_LOADING = "CREATE_EMPLOYEE_LOADING"
export const CREATE_EMPLOYEE_SUCCESS = "CREATE_EMPLOYEE_SUCCESS"
export const CREATE_EMPLOYEE_ERROR = "CREATE_EMPLOYEE_ERROR"



export const SET_SELECTED_EMPLOYEE = 'SET_SELECTED_EMPLOYEE'

export const setSelectedEmployee = (selectedEmployee) => ({ type: SET_SELECTED_EMPLOYEE, data: selectedEmployee })
export const getEmployees = (body) => ({ type: GET_EMPLOYEES, body })
export const getEmployeeById = (body) => ({ type: GET_EMPLOYEE, body })
export const updateEmployeeById = (body) => ({ type: UPDATE_EMPLOYEE, body })
export const createEmployee = (body) => ({ type: CREATE_EMPLOYEE, body })


export const resetEmployees = () => ({ type: RESET_EMPLOYEES })
export const resetEmployee = () => ({ type: RESET_EMPLOYEE })