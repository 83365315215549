import React from 'react'
import { Tag } from 'antd';
import { formatDate, toCurrency } from "../../utils/helper";
import { Button, Tooltip, Typography } from 'antd';
import { getColorByStatus, getIconByStatus } from "../../utils/formatStatus";
const { Text } = Typography
export const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        // render: item => <Text style={item === "Total Deductions" ? { fontSize: 20, fontWeight: 700 } : {}}>{item}</Text>,
        render: (text, record) => {
            const truncatedId = text.substring(0, 5);
            return (
                <Tooltip title="Click to copy">
                    <Button type="link" onClick={() => navigator.clipboard.writeText(text)}>
                        {truncatedId}...
                    </Button>
                </Tooltip>
            );
        },
        width: '10%',
        align: "center"
    },
    {
        title: 'Name',
        dataIndex: 'name',
        width: '20%',
        key: 'name'
    },
    {
        title: 'Working Type',
        dataIndex: 'workingType',
        width: '20%',
        key: 'workingType'
    },
    {
        title: 'From Date',
        dataIndex: 'from',
        width: '12%',
        key: 'from',
        render: item => <Text>{formatDate(item, 'DD/MM/YYYY')}</Text>
    },
    {
        title: 'To Date',
        dataIndex: 'to',
        width: '12%',
        key: 'to',
        render: item => <Text>{formatDate(item, 'DD/MM/YYYY')}</Text>
    },
    {
        title: 'Locking Date',
        dataIndex: 'lockingDate',
        width: '12%',
        key: 'to',
        render: item => <Text>{formatDate(item, 'DD/MM/YYYY')}</Text>
    },
    {
        title: 'Pay Date',
        dataIndex: 'payDate',
        width: '12%',
        key: 'to',
        render: item => <Text>{formatDate(item, 'DD/MM/YYYY')}</Text>
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: "20%",
        align: "center",
        render: item =>
            <Tag
                icon={getIconByStatus(item?.toUpperCase())}

                style={{ minWidth: '70%' }}
                color={getColorByStatus(item?.toUpperCase())} key={item} w>
                {item?.toUpperCase()}
            </Tag>,
    },

    {
        title: 'Total Pays Amount',
        dataIndex: 'totalPaysAmount',
        width: '20%',
        align: "left",
        key: 'totalPaysAmount',
        render: item => <Text>
            {toCurrency(Number(item), "gbp")}</Text>
    },

];



export const workingTypes = [
    {
        label: 'Standard',
        value: "STANDARD"
    },
    {
        label: "Casual",
        value: "CASUAL"
    }
]